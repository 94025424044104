/* src/components/Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  
  .logo-title {
    display: flex;
    align-items: center;
  }
  
  .logo-title h2 {
    margin-left: 10px;
  }
  
  .nav-links a {
    margin-left: 20px;
    text-decoration: none;
    color: black;
  }
  
  .logo-title a {
    
    text-decoration: none;
    color: black;
  }