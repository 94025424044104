#container {
    width: 60%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 15px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.cardContainer {
    display: flex; /* Use flexbox layout */
    flex-wrap: wrap; /* Allow wrapping to the next row */
    gap: 20px; /* Add space between cards */
    text-align: center;
}
.card {
    flex: 1;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 15px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 25%; /* Limit max-width to 25% for each card */
    box-sizing: border-box
}

h1 {
    text-align: center;
    color: #333;
}

p {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #555;
}
.cardContainer a {
    display: block;
}

.envSwitch {
    text-align: right;
}