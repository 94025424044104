.mainImage {
    text-align: center;
}

#rightPanel
{
    width: 25%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 15px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
    margin-right: 0.1px;
}

.labelContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.serverStatusOnline, .serverStatusOffline {
    padding: 2px 6px;
    border-radius: 4px;
    font-weight: bold;
}

.serverStatusOnline {
    background-color: green;
    color: white;
}

.serverStatusOffline {
    background-color: red;
    color: white;
}